
.rect-bg{
     background-color: rgba(0, 0, 0, 0.3); 
     background-image: url('../../images/watch-bg.webp');
     background-size: cover;
     background-position: center center;
     padding: 30px;
     border-radius: 20px;
     display:block;
     width: 60rem;
     height: 40rem;
}
.eco-section{
     height: 50vh;
     background: conic-gradient(from 180deg, #21CD85, #21CD85);
}
#intro {
     background-image: url("../../images/card3.webp");
     background-size: cover;
     height: 100vh;
     background-position: center;
   }

   /* Height for devices larger than 576px */
   @media (min-width: 992px) {
     #intro {
       margin-top: -58.59px;
     }
   }
.mask{
     background-color: rgba(0, 0, 0, 0.4);
}
.card101{
     width: 70%;
}

@media only screen and (max-width: 600px){
     .card101{
          width: 100%;
     }
     .hand-card{
          background-image: url("../../images/card1.webp");
          background-size: contain;
          height: 100vh;
          background-position: center;
          background-repeat: no-repeat;
     }
}
.hand-card{
     background-image: url("../../images/card1.webp");
     background-size: contain;
     height: 65vh;
     background-position: right;
     background-repeat: no-repeat; 
}
.faqss{
     max-width: 500px;
}

.card1{
     background-position:bottom right;
     background-image:url('../../images/card1.webp');
     background-size: 60%;
     background-repeat: no-repeat;
}

.header-img{
     width: 30rem;
}

.header-title{
     font-size: 35px;
     /* color: #fff; */
}

.header-text{
     font-size: 35px;
     /* color: #fff; */
}

@media only screen and (max-width: 600px){
     .card1{
          background-size: 200%;
          background-position:bottom left;
     }
     .header-title{
          font-size: 35px;
     }
     .header-text{
          font-size: 35px;
     }
}

.mask{

     background: linear-gradient(
          45deg,
          rgba(0, 0, 0, 0.7),
          rgba(0, 0, 0, 0.7) 100%
     );
      
}
.agrad{
     background: conic-gradient(from 180deg, #1B4078, #229FA7);
}

.h-screen{
     height: 100vh;
}
.agrad-color{
     background-color: #ffffff;
     background-image: conic-gradient(from 180deg, #1B4078, #229FA7);
     background-size: 100%;
     -webkit-background-clip: text;
     -moz-background-clip: text;
     -webkit-text-fill-color: transparent; 
     -moz-text-fill-color: transparent;
}
.conbg-circle{
     background: #ffffff;
     background-image: conic-gradient(from 180deg, #1B4078, #229FA7);
     padding: 30px;
     border-radius: 50%;
     display:block;
     width: 18rem;
     height: 18rem;
}
.cl-rel{
     position: relative;
     left: 47%;
     top: 20%;
}
.cr-rel{
     position: relative;
     right: 80%;
     bottom: 100%;
}